<template>
    <v-dialog :persistent="deletingToken" v-model="dialog" max-width="500">
        <template v-slot:activator="{ on: dialog }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn color="secondary" small icon>
                        <v-icon dark small v-on="{ ...tooltip, ...dialog }">delete</v-icon>
                    </v-btn>
                </template>
                Delete
            </v-tooltip>
        </template>

        <v-card>
            <v-card-title>
                <div class="secondary--text">
                    <v-icon class="mr-1">delete</v-icon>
                    {{ dialogTitle }}
                </div>
            </v-card-title>
            <v-divider class="mb-1"></v-divider>
            <v-card-text>
                <div class="font-weight-bold mb-5">Are you sure you want to delete the following token?</div>
                <div class="subtitle-2">{{ token }}</div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="deletingToken" color="secondary" text @click="dialog = false">cancel</v-btn>
                <v-btn :loading="deletingToken" color="error" text @click="deleteToken()">delete</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: 'DeleteTokenDialog',
    props: {
        token: String,
        dialogTitle: String,
        apiURL: String,
        fetchTokensDispatch: Function
    },
    data() {
        return {
            dialog: false,
            deletingToken: false
        }
    },
    methods: {
        deleteToken: function () {
            this.deletingToken = true
            this.$axios
                .delete(this.$props.apiURL)
                .then(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Token deleted successfully!',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                    this.$props.fetchTokensDispatch()
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Could not delete token.',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.deletingToken = false
                    this.dialog = false
                })
        }
    }
}
</script>
